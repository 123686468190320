@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8f9;
  overflow-y: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none !important;
}

a u {
  text-decoration: none !important;
}

.ql-tooltip {
  display: none;
}

textarea {
  outline: none !important;
  padding: 3px 4px;
}

input[type='number'] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important; /* Firefox specific: remove increment arrows */
  appearance: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.ql-container {
  margin-top: 10px !important;
}

.swal2-html-container a {
  color: rgb(37 99 235);
  text-decoration: underline !important;
}

.ql-toolbar {
  margin-bottom: 30px !important;
}

.quill h2 {
  font-size: 18px !important;
  font-weight: bold;
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px !important;
}

.react-datepicker__input-container {
  width: 100%;
  height: 100%;
  border-radius: 6px !important;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  color: black !important;
  padding-left: 20px;
  border-radius: 6px !important;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.pg-photo-container {
  width: 100% !important;
  height: 100% !important;
}

img {
  width: 100% !important;
  height: 100% !important;
}

@property --rotate {
  syntax: '<angle>';
  initial-value: 132deg;
  inherits: false;
}

.shimmer-border {
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  gap: 8px;
}

.shimmer-border::before {
  content: '';
  width: 101%; /* Adjusted to match the parent width */
  height: 101%; /* Adjusted to match the parent height */
  border-radius: 999px; /* Adjust to match the inner div's radius if needed */
  position: absolute;
  z-index: -1;
  top: 0; /* Centered to parent */
  left: 1; /* Centered to parent */
  background-image: linear-gradient(90deg, #5ddcff, #3c67e3 50%, #0811bb);
  opacity: 0;
  transition: opacity 0.4s;
}

/* Add hover effect */
.shimmer-border:hover::before {
  opacity: 1;
}

.shimmer-border.border-active::before {
  opacity: 1;
}
