.RecklessNeue {
  font-family: 'Reckless Neue';
}

.LotaGrotesque {
  font-family: 'Lota Grotesque';
}

.introjs-button {
  background-color: #2563eb !important;
  color: white !important;
  text-shadow: none !important;
  border: none !important;
}

.outer-hover:hover .fa-arrow-bounce {
  animation: bounce-lr 500ms ease-in-out 2;
  animation-delay: 0s, 7s;
  animation-fill-mode: forwards;
}

@keyframes bounce-lr {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}
